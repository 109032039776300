<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myorder' }"><span @click="getClear">{{$t('message.myorder')}}</span></el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.order')}}：{{orderCode}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【订单状态】 -->
        <b-container class="pc-shopcar">
            <div class="pc-shopwrap">
                <!-- 订单基本信息 -->
                <div class="pc-shop">
                    <div class="pc-title">{{$t('message.orddetail')}}</div>
                    <div class="pc-info">
                        <div class="pc-label">{{$t('message.shoshopinfo')}}</div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.shoppeple')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.user_name}} +{{languageCodeObj&&languageCodeObj.mobile_area_code}} {{orderInfoObj.mobile}}</div>
                        </div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.shoaddress')}}：</div>
                            <!-- <div class="pc-txt">{{orderInfoObj.info}}，{{orderInfoObj.country}}，{{orderInfoObj.city}},{{orderInfoObj.apartment}}</div> -->
                            <div class="pc-txt">{{orderInfoObj.info}}，{{orderInfoObj.country}}，{{orderInfoObj.city}}</div>
                        </div>
                        <!-- <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.doorling')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.doorbell}}</div>
                        </div> -->
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.postcode')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.postcode}}</div>
                        </div>
                    </div>
                    <div class="pc-info">
                        <div class="pc-label">{{$t('message.orddetail')}}</div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.ordernum')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.order_no}}</div>
                        </div>
                        <div class="pc-info-list">
                            <div class="pc-name">{{$t('message.ortime')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.create_time}}</div>
                        </div>
                        <div class="pc-info-list" v-show="orderInfoObj.pay_time">
                            <div class="pc-name">{{$t('message.pytime')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.pay_time}}</div>
                        </div>
                        <div class="pc-info-list" v-show="orderInfoObj.delivery_time">
                            <div class="pc-name">{{$t('message.ftime')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.delivery_time}}</div>
                        </div>
                        <div class="pc-info-list" v-show="orderInfoObj.complete_time">
                            <div class="pc-name">{{$t('message.fintime')}}：</div>
                            <div class="pc-txt">{{orderInfoObj.complete_time}}</div>
                        </div>
                    </div>
                </div>
                <!-- 订单状态 -->
                <div class="pc-all">
                    <div class="pc-order-info-state">
                        <div class="pc-title-state">
                            <div class="pc-state-name">
                                <span class="pc-orderstate"></span>{{$t('message.state0')}}：
                            </div>
                            <div class="pc-state-txt" v-show="orderInfoObj.order_status==1">{{$t('message.state1')}}</div>
                            <div class="pc-state-txt" v-show="orderInfoObj.order_status==2">
                                <div class="pc-title">{{$t('message.state2')}}</div>
                                <div class="pc-title">{{orderInfoObj.type2==1?'':$t('message.writstate1')}}</div>
                            </div>
                            <div class="pc-state-txt" v-show="orderInfoObj.order_status==3">{{$t('message.state3')}}</div>
                            <div class="pc-state-txt" v-show="orderInfoObj.order_status==4">{{$t('message.state4')}}</div>
                            <div class="" v-show="orderInfoObj.order_status==5">
                                <div class="pc-state-txt" v-show="orderInfoObj.after_sale<5">{{$t('message.state5')}}</div>
                                <div class="pc-state-txt" v-show="orderInfoObj.after_sale==5">{{$t('message.state6')}}</div>
                                <div class="pc-state-txt" v-show="orderInfoObj.after_sale==6">{{$t('message.showstate6')}}</div>
                                <div class="pc-state-txt" v-show="orderInfoObj.after_sale==7">{{$t('message.showstate7')}}</div>
                            </div>
                        </div>
                        <div class="pc-pay-state pc-marg" v-show="orderInfoObj.order_status==1">
                            {{$t('message.ycan')}}：<span class="pc-paybtn" @click="getSubmit('web')">{{$t('message.paytotal')}}</span>
                        </div>
                        <!-- 支付方式 -->
                        <div class="pc-pay-state" v-show="orderInfoObj.order_status>1">
                            <span class="pc-label">{{$t('message.paytype')}}：</span>
                            <span class="pc-leg">
                                {{orderInfoObj.price_type==1?$t('message.wchartpay'):''}}
                                {{orderInfoObj.price_type==2?$t('message.zfbpay'):''}}
                                {{orderInfoObj.price_type==3?$t('message.xykpay'):''}}
                                {{orderInfoObj.price_type==4?$t('message.xxpay'):''}}
                                {{orderInfoObj.type2==1?'':'/'+$t('message.hdfkpay')}}
                            </span>
                        </div>
                        <!-- 物流 -->
                        <div class="pc-pay-state" v-show="orderInfoObj.order_status>2">
                            <span class="pc-label">{{$t('message.logis')}}：</span>
                            <span class="pc-leg">邮政快递 {{orderInfoObj.racking_number}}</span>
                        </div>
                        <div class="pc-pay-state pc-marg" v-show="orderInfoObj.order_status==3">
                            {{$t('message.ycan')}}：<span class="pc-paybtn" @click="getSubmitReceiving(orderInfoObj)">{{$t('message.comfiresh')}}</span>
                            <span class="pc-ifo" @click="getLogisticsInfo">{{$t('message.seelog')}}</span>
                        </div>
                        <div class="pc-pay-state pc-marg" v-show="orderInfoObj.order_status==4">
                            {{$t('message.ycan')}}：<span class="pc-ifo" @click="getEvaluateInfo">{{$t('message.commentxt')}}</span>
                            <span class="pc-ifo" @click="getLogisticsInfo">{{$t('message.seelog')}}</span>
                            <span class="pc-ifo" v-show="orderInfoObj.type2==1" @click="getShowInfo(1)">{{$t('message.shoinfo')}}</span>
                            <span class="pc-ifo" @click="getDelete">{{$t('message.delorder')}}</span>
                        </div>
                        <div class="pc-pay-state" v-show="orderInfoObj.order_status==5">
                            <div>
                                <span class="pc-label">{{$t('message.thaddress')}}：</span>
                                <span class="pc-leg">{{orderInfoObj.consignee_name}} +{{languageCodeObj&&languageCodeObj.mobile_area_code}}{{orderInfoObj.consignee_telno}}</span>
                            </div>
                            <div>
                                <span class="pc-label"></span>
                                <span class="pc-leg">
                                    {{languageName=='cn'?orderInfoObj.cn_refund_address:''}}
                                    {{languageName=='en'?orderInfoObj.en_refund_address:''}}
                                    {{languageName=='it'?orderInfoObj.it_refund_address:''}}
                                </span>
                            </div>
                            <div>
                                <span class="pc-label"></span>
                                <span class="pc-leg">{{orderInfoObj.consignee_postcode}}</span>
                            </div>
                        </div>
                        <div class="pc-pay-state" v-show="orderInfoObj.order_status==5">·{{$t('message.state9')}}</div>
                        <div class="pc-pay-state pc-marg" v-show="orderInfoObj.order_status==5&&orderInfoObj.type2==1">
                            {{$t('message.ycan')}}：<span class="pc-ifo" @click="getShowInfo(2)">{{$t('message.quyinfo')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
        <!-- pc布局【订单信息】 -->
        <b-container class="pc-shopintab">
            <div class="pc-order-header">
                <div class="head-th col1">{{$t('message.shop')}}</div>
                <div class="head-th col2">{{$t('message.priceshop')}}</div>
                <div class="head-th col3">{{$t('message.num')}}</div>
                <div class="head-th col4">{{$t('message.allmpney')}}</div>
                <div class="head-th col5">{{$t('message.status')}}</div>
            </div>
            <div class="pc-order-group">
                <div class="order-item" v-for="(item,index) in orderData" :key="index">
                    <div class="order-wrap">
                        <div class="order-shop">
                            <div class="order-shop-item" v-for="(val,key) in item.goods" :key="key">
                                <div class="order-shop-img">
                                    <div class="shop-img"><img :src="val.goods_img" alt=""></div>
                                    <div class="shop-title" v-show="languageName=='cn'">{{val.cn_name}}（{{val.cn_spect_name}}）</div>
                                    <div class="shop-title" v-show="languageName=='en'">{{val.en_name}}（{{val.en_spect_name}}）</div>
                                    <div class="shop-title" v-show="languageName=='it'">{{val.it_name}}（{{val.it_spect_name}}）</div>
                                </div>
                                <div class="order-shop-money">{{$t('message.m')}}{{val.price}}</div>
                                <div class="order-shop-number">{{val.goods_num}}</div>
                            </div>
                        </div>
                        <div class="order-money">
                            <div class="cen">
                                <div class="money">{{$t('message.m')}}{{item.totalmoney}}</div>
                                <div class="money" v-show="(item&&item.totalaams!='0.00')">AAMS{{$t('message.m')}}{{item.totalaams}}</div>
                                <div class="money" v-show="(item&&item.totaliva!='0.00')">IVA{{$t('message.m')}}{{item.totaliva}}</div>
                            </div>
                        </div>
                        <div class="order-more-item" >
                            <div class="cen" v-for="(val,key) in item.goods" :key="key">
                                <div class="txt">
                                    {{item.order_status==1?$t('message.writpaylab'):''}}
                                    {{item.order_status==2?$t('message.writfh'):''}}
                                    {{item.order_status==3?$t('message.writshlab'):''}}
                                    {{item.order_status==4?$t('message.state4'):''}}
                                    <span v-show="item.order_status==5" class="sh">{{$t('message.solding')}}</span>
                                    <span v-show="item.order_status==6" class="tk">{{$t('message.tksuccess')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order-foot">
                        <div class="order-foot-shopmoney">{{$t('message.shopmoney')}}:<span>{{$t('message.m')}}{{orderInfoObj.money}}</span></div>
                        <div class="order-foot-post">{{$t('message.postmoney')}}:<span>{{$t('message.m')}}{{orderInfoObj.postage}}</span></div>
                        <div class="order-foot-shopmoney" v-show="(orderInfoObj&&orderInfoObj.totalaams!='0.00')">AAMS:<span>{{$t('message.m')}}{{orderInfoObj.totalaams}}</span></div>
                        <div class="order-foot-shopmoney" v-show="(orderInfoObj&&orderInfoObj.totaliva!='0.00')">IVA:<span>{{$t('message.m')}}{{orderInfoObj.totaliva}}</span></div>
                        <div class="order-foot-money">{{$t('message.paymoeny')}}<span v-show="orderInfoObj.type2==2">（{{$t('message.hdfkpay')}}）</span>:<span class="span">{{$t('message.m')}}{{orderInfoObj.totalmoney}}</span></div>
                    </div>
                </div>
                <div class="order-btn" v-show="orderInfoObj.order_status==1">
                    <span class="paybtn" @click="getSubmit('web')">{{$t('message.nowpay')}}</span>
                </div>
            </div>
        </b-container>
        <!-- wap布局【订单状态和订单布局】 -->
        <b-container class="wap-shopcar">
            <!-- 订单状态 -->
            <b-row class="wap-shop-state">
                <div class="wap-title-state">
                    <div class="wap-state-name">
                        <span class="wap-orderstate"></span>{{$t('message.state0')}}：
                    </div>
                    <div class="wap-state-txt" v-show="orderInfoObj.order_status==1">{{$t('message.state1')}}</div>
                    <div class="wap-state-txt" v-show="orderInfoObj.order_status==2">
                        <div class="wap-title">{{$t('message.state2')}}</div>
                        <div class="wap-title">{{orderInfoObj.type2==1?'':$t('message.writstate1')}}</div>
                    </div>
                    <div class="wap-state-txt" v-show="orderInfoObj.order_status==3">{{$t('message.state3')}}</div>
                    <div class="wap-state-txt" v-show="orderInfoObj.order_status==4">{{$t('message.state4')}}</div>
                    <div class="wap-state-txt" v-show="orderInfoObj.order_status==5">
                        <span v-show="orderInfoObj.after_sale<5">{{$t('message.state5')}}</span>
                        {{orderInfoObj.after_sale==5?$t('message.state6'):''}}
                        {{orderInfoObj.after_sale==6?$t('message.showstate6'):''}}
                        {{orderInfoObj.after_sale==7?$t('message.showstate7'):''}}
                    </div>
                </div>
                <div class="wap-pay-state wap-marg" v-show="orderInfoObj.order_status==1"><span class="wap-label_foot">{{$t('message.ycan')}}：</span><span class="wap-paybtn" @click="getSubmit('mobile')">{{$t('message.paytotal')}}</span></div>
                <div class="wap-pay-state wap-marg" v-show="orderInfoObj.order_status>1">
                    <span class="wap-label">{{$t('message.paytype')}}：</span>
                    <span class="wap-leg">
                        {{orderInfoObj.price_type==1?$t('message.wchartpay'):''}}
                        {{orderInfoObj.price_type==2?$t('message.zfbpay'):''}}
                        {{orderInfoObj.price_type==3?$t('message.xykpay'):''}}
                        {{orderInfoObj.price_type==4?$t('message.xxpay'):''}}
                        {{orderInfoObj.type2==1?$t('message.zxpay'):'/'+$t('message.hdfkpay')}}
                    </span>
                </div>
                <div class="wap-pay-state" v-show="orderInfoObj.order_status>2">
                    <span class="wap-label">{{$t('message.logis')}}：</span>
                    <span class="wap-leg">邮政快递 {{orderInfoObj.racking_number}}</span>
                </div>
                <div class="wap-pay-state wap-marg" v-show="orderInfoObj.order_status==3">
                    <span class="wap-label_foot">{{$t('message.ycan')}}：</span>
                    <span class="wap-paybtn" @click="getSubmitReceiving(orderInfoObj)">{{$t('message.comfiresh')}}</span>
                    <span class="wap-ifo" @click="getLogisticsInfo">{{$t('message.seelog')}}</span>
                </div>
                <div class="wap-pay-state wap-marg" v-show="orderInfoObj.order_status==4">
                    <span class="wap-label_foot">{{$t('message.ycan')}}：</span>
                    <span class="wap-ifo" @click="getEvaluateInfo">{{$t('message.commentxt')}}</span>
                    <span class="wap-ifo" @click="getLogisticsInfo">{{$t('message.seelog')}}</span>
                    <span class="wap-ifo" v-show="orderInfoObj.type2==1" @click="getShowInfo(1)">{{$t('message.shoinfo')}}</span>
                    <span class="wap-ifo" @click="getDelete">{{$t('message.delorder')}}</span>
                </div>
                <div class="wap-pay-state" v-show="orderInfoObj.order_status==5">
                    <div>
                        <span class="wap-label">{{$t('message.thaddress')}}：</span>
                        <span class="wap-leg">{{orderInfoObj.consignee_name}} +{{languageCodeObj&&languageCodeObj.mobile_area_code}}{{orderInfoObj.consignee_telno}}</span>
                    </div>
                    <div>
                        <span class="wap-label"></span>
                        <span class="wap-leg">
                            {{languageName=='cn'?orderInfoObj.cn_refund_address:''}}
                            {{languageName=='en'?orderInfoObj.en_refund_address:''}}
                            {{languageName=='it'?orderInfoObj.it_refund_address:''}}
                        </span>
                    </div>
                    <div>
                        <span class="wap-label"></span><span class="wap-leg">{{orderInfoObj.consignee_postcode}}</span>
                    </div>
                </div>
                <div class="wap-pay-state" v-show="orderInfoObj.order_status==5"><span class="wap-txt">·{{$t('message.state9')}}</span></div>
                <div class="wap-pay-state wap-marg" v-show="orderInfoObj.order_status==5&&orderInfoObj.type2==1">
                    <span class="wap-label_foot">{{$t('message.ycan')}}：</span>
                    <span class="wap-ifo" @click="getShowInfo(2)">{{$t('message.quyinfo')}}</span>
                </div>
            </b-row>
            <!-- 订单信息 -->
            <b-row class="wap-info-shop">
                <div class="wap-info-title">{{$t('message.orddetail')}}</div>
                <div class="wap-info-box">
                    <div class="wap-info-label">{{$t('message.shoshopinfo')}}</div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.shoppeple')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.user_name}} +{{languageCodeObj.mobile_area_code}} {{orderInfoObj.mobile}}</div>
                    </div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.shoaddress')}}：</div>
                        <!-- <div class="wap-info-txt">{{orderInfoObj.info}}，{{orderInfoObj.country}}，{{orderInfoObj.city}},{{orderInfoObj.apartment}}</div> -->
                        <div class="wap-info-txt">{{orderInfoObj.info}}，{{orderInfoObj.country}}，{{orderInfoObj.city}}</div>
                    </div>
                    <!-- <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.doorling')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.doorbell}}</div>
                    </div> -->
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.postcode')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.postcode}}</div>
                    </div>
                </div>
                <div class="wap-info-box">
                    <div class="wap-info-label">{{$t('message.orddetail')}}</div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.ordernum')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.order_no}}</div>
                    </div>
                    <div class="wap-info-list">
                        <div class="wap-info-name">{{$t('message.ortime')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.create_time}}</div>
                    </div>
                    <div class="wap-info-list" v-show="orderInfoObj.pay_time">
                        <div class="wap-info-name">{{$t('message.pytime')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.pay_time}}</div>
                    </div>
                    <div class="wap-info-list" v-show="orderInfoObj.delivery_time">
                        <div class="wap-info-name">{{$t('message.ftime')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.delivery_time}}</div>
                    </div>
                    <div class="wap-info-list" v-show="orderInfoObj.complete_time">
                        <div class="wap-info-name">{{$t('message.fintime')}}：</div>
                        <div class="wap-info-txt">{{orderInfoObj.complete_time}}</div>
                    </div>
                </div>
            </b-row>
            <!-- 订单信息-商品 -->
            <b-row class="wap-shop-wrap" v-show="orderData.length>0">
                <div class="wap-shop-data" v-for="(item,index) in orderData" :key="index">
                    <div class="wap-shop-item" v-for="(val,key) in item.goods" :key="key">
                        <div class="wap-shop-img"><img :src="val.goods_img" alt=""/></div>
                        <div class="wap-shop-txt">
                            <div class="wap-title">
                                {{languageName=='cn'?val.cn_name:''}}
                                {{languageName=='en'?val.en_name:''}}
                                {{languageName=='it'?val.it_name:''}}
                            </div>
                            <div class="wap-txt">
                                {{languageName=='cn'?val.cn_spect_name:''}}
                                {{languageName=='en'?val.en_spect_name:''}}
                                {{languageName=='it'?val.it_spect_name:''}}
                            </div>
                            <div class="wap-shopnum">{{$t('message.m')}}{{val.price}}</div>
                        </div>
                        <div class="wap-shop-shbtn">
                            <div class="wap-del-shopbtn">&times;{{val.goods_num}}</div>
                            <div class="wap-shop-status">
                                {{val.state==1?$t('message.writpaylab'):''}}
                                {{val.state==2?$t('message.writfh'):''}}
                                {{val.state==3?$t('message.writshlab'):''}}
                                {{val.state==4?$t('message.state4'):''}}
                                <span v-show="val.state==5" class="sh">{{$t('message.solding')}}</span>
                                <span v-show="val.state==6" class="tk">{{$t('message.tksuccess')}}</span>    
                            </div>
                        </div>
                    </div>
                    <div class="wap-calcul">
                        <div class="wap-calcul-group">
                            <div class="wap-calcul-txt">{{$t('message.shopmoney')}}</div>
                            <div class="wap-calcul-num">{{$t('message.m')}}{{orderInfoObj.money}}</div>
                        </div>
                        <div class="wap-calcul-group">
                            <div class="wap-calcul-txt">{{$t('message.postmoney')}}</div>
                            <div class="wap-calcul-num">
                                {{$t('message.m')}}{{orderInfoObj.postage}}
                                <span class="wap-allnum">({{$t('message.fullpay')}}{{$t('message.m')}}{{amount}}{{$t('message.mpay')}}{{$t('message.freepay')}})</span>
                            </div>
                        </div>
                        <div class="wap-calcul-group" v-show="(orderInfoObj&&orderInfoObj.totalaams!='0.00')">
                            <div class="wap-calcul-txt">AAMS</div>
                            <div class="wap-calcul-num">{{$t('message.m')}}{{orderInfoObj.totalaams}}</div>
                        </div>
                        <div class="wap-calcul-group" v-show="(orderInfoObj&&orderInfoObj.totaliva!='0.00')">
                            <div class="wap-calcul-txt">IVA</div>
                            <div class="wap-calcul-num">{{$t('message.m')}}{{orderInfoObj.totaliva}}</div>
                        </div>
                    </div>
                    <div class="wap-calcul-group">
                        <div class="wap-calcul-txt">{{$t('message.paymoeny')}}</div>
                        <div class="wap-calcul-nums">
                            <span v-show="item.type2==2">（{{$t('message.hdfkpay')}}）：</span>
                            <span class="span">{{$t('message.m')}}{{item.totalmoney}}</span>
                        </div>
                    </div>
                    <div class="link" v-show="orderInfoObj.order_status==1">
                        <span class="linkbtn" @click="getSubmit('mobile')">{{$t('message.nowpay')}}</span>
                    </div>
                </div>
            </b-row>
        </b-container>
        <!-- 删除提示弹窗 -->
        <b-modal size="sm" centered v-model="dellaiog" hide-footer :title="$t('message.tiptitle')">
            <div class="d-block text-center">
                <p style="padding:30px 0;">{{$t('message.comfirdel')}}</p>
            </div>
            <b-button  block @click="toggleModal">{{$t('message.firm')}}</b-button>
        </b-modal>
        <!-- 支付类型提示弹窗 -->
        <b-modal size="sm" centered v-model="paylaiog" hide-footer :title="$t('message.pay')">
            <div class="d-block text-center">
                <div class="pc-input_odd">
                    <div class="pc-pay-type" v-for="(item,index) in paylist" :key="index">
                        <div class="pc-pay-otem"><el-radio v-model="radio" :label="item.id">{{item.name}}</el-radio></div>
                    </div>
                </div>
                <div class="pc-link">
                    <span class="pc-linkbtn" @click="payModal('web')">{{$t('message.paytotal')}}</span>
                    <span class="wap-linkbtn" @click="payModal('mobile')">{{$t('message.paytotal')}}</span>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
var setWechatTimer;
import ElementUI from 'element-ui';
import {yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'myorderinfo',
    inject:['reload'],
    data(){
        return{
            token:'',
            orderCode:'',
            languageName:'',
            languageObj:{},
            orderData:[],
            allMoney:0,
            orderInfoObj:{},
            languageCodeObj:{},
            eachPayInfo:{},
            dellaiog:false,
            login_user_info:{},
            paylaiog:false,
            paylist:[],
            radio:'1',
            amount:'',
            payamount:'',
        }
    },
    methods: {
        // 获取支付类型
        getPayTpye(){
            this.$http.api_order_getpricetype().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            var paylistobj = res.data;
                            var paylistarr = [];
                            for(let i in paylistobj) {
                                paylistarr.push({id:i,name:paylistobj[i],check:false});
                            }
                            this.paylist = paylistarr;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
         // 获取系统保底邮费
        getQueryPostage(){
            this.$http.api_order_getpostage().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.payamount = res.data.pay_postage;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 获取国家包邮金额范围
        getPackAmount(){
            this.$http.api_order_getpackagemailamount({
                country:this.languageName//国家编码
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.amount = res.data.amount;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 选择支付类型弹窗
        payModal(type){
            let params = {
                token:this.token,//（用户token）、
                orderno:this.orderInfoObj.order_no,//（订单编号）、
                pricetype:this.radio,//（支付方式 1微信 2支付宝 3visa 4线下支付）、
                isMobile:type?(type=='web'?'0':'1'):'0',//（是否为手机端 1是 0否）
            }
            this.$http.api_order_payorder(params).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.paylaiog = false;
                            // this.reload();
                            if(res.data.redirect_url){
                                // 轮询订单支付状态
                                //轮询订单状态是否改变
                                // setWechatTimer = setInterval(() => {
                                //     this.getEachPayInfo();
                                // }, 5000);
                                this.eachPayInfo = res.data;
                                // window.open(res.data.redirect_url, '_blank');
                                window.location.href = res.data.redirect_url;
                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 1000);
                            }
                        }
                    }else if(res.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        getClear(){
            clearInterval(setWechatTimer);
        },
        // 删除订单
        getDelete(){
            this.dellaiog = !this.dellaiog;
        },
        hideModal() {
            this.dellaiog = !this.dellaiog;
        },
        toggleModal() {
            this.$http.api_order_deleteorder({
                token:this.token,
                orderno:this.orderInfoObj.order_no
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.dellaiog = false;
                            ElementUI.Message({
                                message:this.languageObj.delsuccess,
                                type:'success',
                                center: true,
                                offset:10,
                            });
                            setTimeout(() => {
                                this.$router.push({path:'/myorder'})
                            }, 1000);
                        }
                    }else if(res.code==-1){
                        // this.reload();
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    }
                }
            });
        },
        // 确认收货
        getSubmitReceiving(item){
            if(this.token&&item){
                // 企业用户
                if(this.login_user_info.is_company==1){
                    if(!this.login_user_info.cinfo.company_name){
                        this.$router.push({path:'/uinfo',query:{type:3}});
                    }else{
                        this.$http.api_order_confirmorder({
                            token:this.token,
                            orderno:item.order_no
                        }).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.orderInfoObj.order_status = 4;
                                        ElementUI.Message({
                                            message:res.message,
                                            type:'success',
                                            center: true,
                                            offset:10,
                                        });
                                    }
                                }else if(res.code==-1){
                                    // this.reload();
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }else{
                    // 普通用户
                    if(!this.login_user_info.family_name){
                        this.$router.push({path:'/uinfo',query:{type:1}});
                    }else{
                        this.$http.api_order_confirmorder({
                            token:this.token,
                            orderno:item.order_no
                        }).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.orderInfoObj.order_status = 4;
                                        ElementUI.Message({
                                            message:res.message,
                                            type:'success',
                                            center: true,
                                            offset:10,
                                        });
                                    }
                                }else if(res.code==-1){
                                    // this.reload();
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 立即支付
        getSubmit(tp){
            if(!this.login_user_info.family_name){
                this.$router.push({path:'/uinfo',query:{type:1}});
            }else{
                clearInterval(setWechatTimer);
                this.paylaiog = !this.paylaiog;
                // 获取订单详情
                // this.$http.api_order_getorderinfo({
                //     token:this.token,
                //     orderno:this.orderInfoObj.order_no
                // }).then((res)=>{
                //     if(res){
                //         if(res.code==1){
                //             if(res.data){
                //                 let params = {
                //                     token:this.token,//（用户token）、
                //                     orderno:this.orderInfoObj.order_no,//（订单编号）、
                //                     pricetype:res.data.price_type,//（支付方式 1微信 2支付宝 3visa 4线下支付）、
                //                     isMobile:tp?(tp=='web'?'0':'1'):'0',//（是否为手机端 1是 0否）
                //                 }
                //                 this.$http.api_order_payorder(params).then((resp)=>{
                //                     if(resp){
                //                         if(resp.code==1){
                //                             if(resp.data){
                //                                 if(resp.data.redirect_url){
                //                                     this.eachPayInfo = resp.data;
                //                                     window.open(resp.data.redirect_url, '_blank');
                //                                     // 轮询订单支付状态
                //                                     //轮询订单状态是否改变
                //                                     setWechatTimer = setInterval(() => {
                //                                         this.getEachPayInfo();
                //                                     }, 5000);
                //                                 }
                //                             }
                //                         }else if(resp.code==-1){
                //                             this.$store.commit('setLoginNameStatus', {});
                //                             delLocalItem('login_user_info');
                //                             this.$root.Bus.$emit('userInfoClick', {});
                //                             this.$store.commit('setLoginStatus',true);
                //                             this.$router.push({path:'/'});
                //                         }else{
                //                             ElementUI.Message({
                //                                 message:res.message,
                //                                 type:'error',
                //                                 center: true,
                //                                 offset:10,
                //                             });
                //                             return false;
                //                         }
                //                     }
                //                 });
                //             }
                //         }else if(res.code==-1){
                //             // this.reload();
                //             this.$store.commit('setLoginNameStatus', {});
                //             delLocalItem('login_user_info');
                //             this.$root.Bus.$emit('userInfoClick', {});
                //             this.$store.commit('setLoginStatus',true);
                //             this.$router.push({path:'/'});
                //         }else{
                //             ElementUI.Message({
                //                 message:res.message,
                //                 type:'error',
                //                 center: true,
                //                 offset:10,
                //             });
                //             return false;
                //         }
                //     }
                // });
            }
        },
        getEachPayInfo(){
            this.$http.api_order_orderquery({
                token:this.token,//（用户token）、
                orderno:this.eachPayInfo.order_no,//（订单编号）、
                transactionnumber:this.eachPayInfo.transaction_no
            }).then((respone)=>{
                if(respone){
                    if(respone.code==1){
                        if(respone.data){
                            clearInterval(setWechatTimer);
                        }
                    }else if(respone.code==-1){
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        // ElementUI.Message({
                        //     message:respone.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            });
        },
        // 查看物流信息
        getLogisticsInfo(){
            this.$router.push({path:'/logistics',query: {order_no: this.orderCode}})
        },
        // 售后
        getShowInfo(type){
            this.$router.push({path:'/myordershowinfo',query: {order_no: this.orderCode,type:type}})
        },
        // 评价
        getEvaluateInfo(){
            this.$router.push({path:'/evaluate',query: {order_no: this.orderCode}})
        },
        // 获取订单详情
        getOrderInfo(token,order_no){
            this.$http.api_order_getorderinfo({
                token:token,
                orderno:order_no
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            let aamsstr_arr = []
                            let ivastr_arr = []
                            this.orderInfoObj = res.data;
                            if(res.data.totalaams){
                                aamsstr_arr = res.data.totalaams.split('.')
                                this.orderInfoObj.totalaams = aamsstr_arr[0]+'.'+aamsstr_arr[1].slice(0,2)
                            }
                            if(res.data.totaliva){
                                ivastr_arr = res.data.totaliva.split('.')
                                this.orderInfoObj.totaliva = ivastr_arr[0]+'.'+ivastr_arr[1].slice(0,2)
                            }
                            this.orderInfoObj = res.data;
                            this.radio = String(res.data.price_type);
                            let arr = [res.data];
                            let money = 0;
                            arr.map((v)=>{
                                arr.map((i)=>{
                                    if(i.totalprice){
                                        money+=i.totalprice
                                    }
                                });
                                v.totalprice = money;
                            })
                            this.orderData = [res.data];
                        }
                    }else if(res.code==-1){
                        // this.reload();
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    }
                }
            });
        }
    },
    mounted(){
        let that = this;
        that.$nextTick(()=>{
            that.languageCodeObj = this.$store.state.data.languageNameStatus;
        });
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "logintip":"请先登录",
                "delsuccess":"删除成功",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "logintip":"please log in first",
                "delsuccess":"Successfully deleted",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "logintip":"fai prima il log in",
                "delsuccess":"Eliminato con successo",
            }
        }
        
        let login_user_info = this.$store.state.data.loginNameStatus.family_name?this.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);
        if(login_user_info){
            this.login_user_info = login_user_info;
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            let routes = this.$route.query;//地址栏参数
            if(routes){
                clearInterval(setWechatTimer);
                this.eachPayInfo = routes;
                this.orderCode = routes.order_no;
                // if(routes.transaction_no){
                //     setWechatTimer = setInterval(() => {
                //         this.getEachPayInfo();
                //     }, 5000);
                // }
                this.getOrderInfo(token,routes.order_no);// 获取订单详情
                this.getPayTpye();
                this.getPackAmount();
            }
        }else{
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>

.text-center{
    text-align: left !important;
    .pc-title{
        font-size:18px;
        padding-bottom: 10px;
    }
    .pc-input_odd{
        border:1px solid #eee;
        border-radius:5px;
        margin-bottom: 20px;
        background: #fff;
        .pc-pay-type{
            padding:10px 0 0;
            .pc-pay-otem{
                padding:0px 15px 10px;
                border-bottom:1px solid #eee;
                position: relative;
                .pc-icon-img{
                    position: absolute;
                    right:10px;
                    top:0;
                    display: inline-block;
                    width:70px;
                    height:30px;
                    .pc-payicon{
                        display: inline-block;
                        width: 30px;
                        height:22px;
                        margin-right: 10px;
                        vertical-align: middle;
                        &:last-child{
                            margin-right: 0px;

                        }
                    }
                    
                    .pc-visa{
                        background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .pc-load{
                        background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    
                }
                /deep/.el-radio__inner{
                    width:20px;
                    height:20px;
                }
                /deep/.el-radio__label{
                    
                }
                /deep/.el-radio__input.is-checked .el-radio__inner{
                    border-color: #1f73b7;
                    background: #1f73b7;
                }
                /deep/.el-radio__input.is-checked+.el-radio__label{
                    color: #222;
                }
            }
            &:last-child{
                .pc-pay-otem{
                    border-bottom:none;
                }
                
            }
            .pc-pay-input{
                background: #f4f7fe;
                padding:10px 20px;
                .pc-input-two{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    height: 58px;
                    width: 100%;
                    margin-bottom: 20px;
                    .pc-t{width:4%}
                    /deep/.el-input--prefix .el-input__inner{
                        padding-left: 20px;
                    }
                    .el-input{
                        width: 48%;
                        height: 100%;
                        /deep/.el-input__inner{
                            height: 100%;
                            color: #95959E;
                            border:1px solid #DDE0ED;
                        }
                        
                        // WebKit 谷歌
                        /deep/input.el-input__inner::-webkit-input-placeholder {
                            color: #95959E;
                        }
                        // Mozilla Firefox 4 - 18 适配火狐
                        /deep/input.el-input__inner:-moz-placeholder { 
                            color: #95959E;
                        }
                        // Mozilla Firefox 19+ 适配火狐
                        /deep/input.el-input__inner::-moz-placeholder {
                            color: #95959E;
                        }
                        // IE 10+  
                        /deep/input.el-input__inner:-ms-input-placeholder { 
                            color: #95959E
                        }
                        /deep/.el-input__icon{display: none;}
                    }
                    input{
                        height: 100%;
                        width: 48%;
                        border:1px solid #DDE0ED;
                        outline: none;
                        padding:0 20px;
                        border-radius:5px;
                        background: #fff;
                        
                        // margin: 0 2%;
                    }
                    // WebKit 谷歌
                    /deep/input::-webkit-input-placeholder {
                        color: #95959E;
                    }
                    // Mozilla Firefox 4 - 18 适配火狐
                    /deep/input:-moz-placeholder { 
                        color: #95959E;
                    }
                    // Mozilla Firefox 19+ 适配火狐
                    /deep/input::-moz-placeholder {
                        color: #95959E;
                    }
                    // IE 10+  
                    /deep/input:-ms-input-placeholder { 
                        color: #95959E
                    }
                }
                .pc-input{
                    height: 58px;
                    width: 100%;
                    border:1px solid #DDE0ED;
                    margin-top: 20px;
                    border-radius:5px;
                    background: #fff;
                    input{
                        height: 100%;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding:0 20px;
                        
                        background: transparent;
                    }
                    // WebKit 谷歌
                    /deep/input::-webkit-input-placeholder {
                        color: #95959E;
                    }
                    // Mozilla Firefox 4 - 18 适配火狐
                    /deep/input:-moz-placeholder { 
                        color: #95959E;
                    }
                    // Mozilla Firefox 19+ 适配火狐
                    /deep/input::-moz-placeholder {
                        color: #95959E;
                    }
                    // IE 10+  
                    /deep/input:-ms-input-placeholder { 
                        color: #95959E
                    }
                }
            }
        }
    }
    .pc-link{
        height: 40px;
        width: 100%;
        text-align: center;
        .pc-linkbtn{
            display: inline-block;
            padding:12px 30px;
            border-radius:4px;
            background: #222;
            color: #fff;
            cursor: pointer;
        }
        .wap-linkbtn{
            display: inline-block;
            padding:12px 30px;
            border-radius:4px;
            background: #222;
            color: #fff;
            cursor: pointer;
        }
    }
}
.navtab{
    padding:20px 10px 10px;
    
}
.pc-shopintab{
    padding:0;
}
.pc-shopcar{
    padding:20px 0;
    .pc-shopwrap{
        width: 100%;
        display: flex;
        -webkit-box-orient: horizontal;
        border:1px solid #dde0ed;
        .pc-shop{
            width: 40%;
            background: #f4f7fe;
            padding:20px 20px 40px;
            .pc-title{
                font-size:18px;
                padding:10px;
                border-bottom: 1px solid #dde0ed;
            }
            .pc-info{
                padding:15px 20px 10px;
                .pc-label{
                    font-size:16px;
                    padding:0px 0 10px;
                }
                .pc-info-list{
                    padding:5px 0;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .pc-name{
                        
                        width:20%;
                        color:#6e6e74;
                    }
                    .pc-txt{
                        width:80%;
                    }
                }
            }
        }
        .pc-all{
            width: 60%;
            padding:70px 60px 40px;
            .pc-order-info-state{
                .pc-title-state{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .pc-state-name{
                        font-size:18px;
                        .pc-orderstate{
                            display: inline-block;
                            width: 36px;
                            height:36px;
                            background: url(~@/assets/images/icon-wraing.png) no-repeat left top;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            margin-right: 20px;
                        }
                    }
                    .pc-state-txt{
                        font-size:18px;
                        vertical-align: middle;
                        padding-top:3px;
                        .pc-title{
                            font-size:18px;
                        }
                    }
                }
                .pc-pay-state{
                    padding-left: 60px;
                    color: #616166;
                    .pc-label{
                        display: inline-block;
                        width: 80px;
                        color: #616166;
                    }
                    .pc-paybtn{
                        display: inline-block;
                        padding:5px 20px;
                        background: #222;
                        color: #fff;
                        cursor: pointer;
                    }
                    .pc-ifo{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 16px;
                    }
                    .pc-leg{
                        display: inline-block;
                        padding:5px 20px;
                        color: #2e2e2e;
                    }

                }
                .pc-marg{
                    margin-top: 40px;
                }
            }
        }
    }
}

.pc-order-header{
    padding:7px 0;
    background: #f4f7fe;
    display: flex;
    -webkit-box-orient: horizontal;
    .head-th{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #616166;
        padding:3px 0;
    }
    .col1{
        width:49%;
        border-right: 1px solid #dde0ed;
    }
    .col2{
        width:10.5%;
        border-right: 1px solid #dde0ed;
    }
    .col3{
        width:10.5%;
        border-right: 1px solid #dde0ed;
    }
    .col4{
        width:15%;
        border-right: 1px solid #dde0ed;
    }
    .col5{
        width:15%;
    }
}
.pc-order-group{
    width: 100%;
    padding:0px 0 0px;
    .order-item{
        background: #f4f7fe;
        .order-wrap{
            border: 1px solid #dde0ed;
            display: flex;
            width: 100%;
            -webkit-box-orient: vertical;
            background: #fff;
            
            .order-shop-group{
                display: flex;
                width: 100%;
                -webkit-box-orient: vertical;
                .shop-detail{
                    display: flex;
                    align-items: center;
                    color: #616166;
                    padding:3px 0;
                    .order-shop-img{
                        width:70%;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        padding: 20px 0;
                        .shop-img{
                            width:30%;
                                height:100px;
                            
                        }
                        .shop-title{
                            width:70%;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
                .col1{
                    width:49%;
                    border-bottom:1px solid #dde0ed;
                    border-right: 1px solid #dde0ed;
                }
                .col2{
                    width:10.5%;
                    justify-content: center;
                    border-bottom:1px solid #dde0ed;
                    border-right: 1px solid #dde0ed;
                }
                .col3{
                    width:10.5%;
                    justify-content: center;
                    border-bottom:1px solid #dde0ed;
                    border-right: 1px solid #dde0ed;
                }
                .col4{
                    width:15%;
                    justify-content: center;
                    border-right: 1px solid #dde0ed;
                }
                .col5{
                    justify-content: center;
                    border-bottom:1px solid #dde0ed;
                    width:15%;
                }
            }
            .order-shop{
                width:70%;
                border-right:1px solid #dde0ed;
                .order-shop-item{
                    display: flex;
                    width: 100%;
                    -webkit-box-orient: horizontal;
                    border-top:1px solid #dde0ed;
                    &:first-child{
                        border-top: none;
                    }
                    .order-shop-img{
                        width:70%;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        padding: 20px 0;
                        border-right:1px solid #dde0ed;
                        .shop-img{
                            width:30%;
                            img{
                                width:80%;
                                height:100px;
                            }
                        }
                        .shop-title{
                            width:70%;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .order-shop-money{
                        width:15%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-right:1px solid #dde0ed;
                        color: #7d7d80;
                    }
                    .order-shop-number{
                        width:15%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        color: #7d7d80;
                    }
                }
            }
            .order-money{
                width:15%;
                display: flex;
                -webkit-box-orient: vertical;
                align-items: center;
                justify-content: center;
                border-right:1px solid #dde0ed;
                .cen{
                    .money{
                        font-weight: bold;
                        
                    }
                    .paytype{
                        padding:2px 0;
                        .red{
                            color:#bf0000;

                        }
                    }
                    .post{
                        
                        color:#95959e;
                    }
                }
            }
            .order-more{
                width:15%;
                display: flex;
                -webkit-box-orient: vertical;
                align-items: center;
                justify-content: center;
                .cen{
                    .paybtn{
                        cursor: pointer;
                        color:#fff;
                        background: #222;
                        padding:5px 20px;
                        margin:3px 0;
                    }
                    .info-order{
                        cursor: pointer;
                        color: #616166;
                        text-align: center;
                        padding:3px 0;
                    }
                }
            }
            .order-more-item{
                width:15%;
                height: 100%;
                .cen{
                    height:50%;
                    padding: 20px 0;
                    border-top:1px solid #dde0ed;
                    display: flex;
                    justify-content: center;
                    &:first-child{
                        border-top: none;
                    }
                    .txt{
                        height:100px;
                        display: flex;
                        align-items: center;
                        .tk{
                            color: #1f73b7;
                        }
                        .sh{
                            color: #bf0000;
                        }
                    }
                }
            }
        }
        .order-foot{
            padding:20px 10px 20px;
            color:#616166;
            .order-foot-shopmoney{
                text-align: right;
                span{
                    display: inline-block;
                    text-align: right;
                    width:150px;
                }
            }
            .order-foot-post{
                text-align: right;
                padding:10px 0;
                span{
                    display: inline-block;
                    text-align: right;
                    width:150px;
                }
            }
            .order-foot-money{
                text-align: right;
                .span{
                    display: inline-block;
                    text-align: right;
                    width:150px;
                    font-size:18px;
                    color: #bf0000;
                    font-weight: bold;
                }
            }
        }
    }
    .order-btn{
        padding:10px 0;
        text-align: right;
        margin-bottom: 20px;
        .paybtn{
            display: inline-block;
            padding:10px 20px;
            border-radius:4px;
            background: #222;
            color: #fff;
            
            cursor: pointer;
        }

    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}
@media screen and (min-width: 1200px) {
     .text-center{
        .pc-link{
            .wap-linkbtn{display: none;}
        }
    }
    .wrap{
        .wap-shopcar{display:none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .text-center{
        .pc-link{
            .wap-linkbtn{display: none;}
        }
    }
    .wrap{
        .wap-shopcar{display:none;}
        .pc-shopcar .pc-shopwrap .pc-shop{width:45%;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info{padding:15px 10px;}
        .pc-shopcar .pc-shopwrap .pc-all{width:55%;padding:70px 10px 40px;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info .pc-info-list .pc-name{width:35%}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info .pc-info-list .pc-txt{width:65%;word-break: break-all;}
        .pc-shopcar .pc-shopwrap .pc-all .pc-order-info-state .pc-pay-state .pc-leg{word-break: break-all;padding-right:0;}
        .pc-shopcar .pc-shopwrap .pc-all .pc-order-info-state .pc-marg{}
        .pc-shopcar .pc-shopwrap .pc-all .pc-order-info-state .pc-pay-state .pc-label{}
        .pc-shopcar .pc-shopwrap .pc-all .pc-order-info-state .pc-pay-state .pc-ifo{}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .text-center{
        .pc-link{
            .wap-linkbtn{display: none;}
        }
    }
    .wrap{
        .wap-shopcar{display:none;}
        .pc-shopcar .pc-shopwrap .pc-shop{width:45%;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info{padding:15px 10px;}
        .pc-shopcar .pc-shopwrap .pc-all{width:55%;padding:70px 10px 40px;}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info .pc-info-list .pc-name{width:35%}
        .pc-shopcar .pc-shopwrap .pc-shop .pc-info .pc-info-list .pc-txt{width:65%;word-break: break-all;}
        .pc-shopcar .pc-shopwrap .pc-all .pc-order-info-state .pc-pay-state .pc-leg{word-break: break-all;padding-right:0;}
        .pc-shopcar .pc-shopwrap .pc-all .pc-order-info-state .pc-marg{}
        .pc-shopcar .pc-shopwrap .pc-all .pc-order-info-state .pc-pay-state .pc-label{}
        .pc-shopcar .pc-shopwrap .pc-all .pc-order-info-state .pc-pay-state .pc-ifo{}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
     .text-center{
        .pc-link{
            .pc-linkbtn{display: none;}
        }
    }
    .wrap{
        .pc-shopcar{display:none;}
        .pc-shopintab{display:none;}
        .wap-shopcar{
            background: #fff;
            padding: 0;
            width: 100%;
            .wap-shop-state{
                padding: 10px;
                margin: 0;
                .wap-title-state{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-state-name{
                        
                        .wap-orderstate{
                            display: inline-block;
                            width: 22px;
                            height:22px;
                            background: url(~@/assets/images/icon-wraing.png) no-repeat left top;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                    .wap-state-txt{
                        
                        vertical-align: middle;
                        .wap-title{
                            
                        }
                    }
                }
                .wap-pay-state{
                    width: 100%;
                    color: #616166;
                    
                    .wap-txt{
                        display: inline-block;
                        padding:0 0 0 35px;
                        width: 100%;
                        color: #616166;
                        
                    }
                    .wap-label{
                        display: inline-block;
                        width: 28%;
                        padding-left: 30px;
                        color: #616166;
                        
                    }
                    .wap-label_foot{
                        display: inline-block;
                        padding-left: 30px;
                        color: #616166;
                        
                    }
                    .wap-paybtn{
                        display: inline-block;
                        padding:5px 20px;
                        background: #222;
                        color: #fff;
                        cursor: pointer;
                        
                    }
                    .wap-ifos{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 0px;
                        
                    }
                    .wap-ifo{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 8px;
                        
                    }
                    .wap-leg{
                        display: inline-block;
                        padding:5px 0px;
                        color: #2e2e2e;
                        
                        width: 70%;
                    }
                    
                }
                .wap-marg{
                    margin-top: 10px;
                }
            }
            .wap-shop-wrap{
                padding:10px;
                background: #fff;
                .wap-shop-data{
                    width: 100%;
                    padding:0px 0 0px;
                    .wap-shop-item{
                        width: 100%;
                        display: flex;
                        padding:0 0 10px 0;
                        -webkit-box-orient: horizontal;
                        .wap-shop-img{
                            width:30%;
                            padding:0 10px 0 0;
                            img{width:100%}
                        }
                        .wap-shop-txt{
                            width:50%;
                            .wap-title{
                                
                                padding:5px 0;
                            }
                            .wap-txt{
                                
                                padding:0px 0 5px;
                                color: #95959e;
                            }
                            .wap-shopnum{
                                
                                margin-top: 15px;
                            }
                        }
                        .wap-shop-shbtn{
                            width:20%;
                            text-align: right;

                            .wap-del-shopbtn{
                                text-align: right;
                                color: #95959e;
                                padding:10px 0px 0;
                            }
                            .wap-shop-status{
                                
                                color: #1f73b7;
                                padding:10px 0px 0;
                            }   
                        }
                    }
                    
                }
                .wap-calcul{
                    width: 100%;
                    padding:20px 0px;
                    .wap-calcul-group{
                        width: 100%;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        padding:5px 0;
                        .wap-calcul-txt{
                            width: 30%;
                        }
                        .wap-calcul-num{
                            width: 70%;
                            text-align: right;
                            .wap-allnum{
                                color: #9c9da5;
                                margin-left: 4px;
                            }
                        }
                    }
                }
                .wap-calcul-group{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:15px 0px;
                    .wap-calcul-txt{
                        width: 30%;
                    }
                    .wap-calcul-nums{
                        width: 70%;
                        text-align: right;
                        
                    }
                }
                .link{
                    height: 40px;
                    width: 100%;
                    text-align: right;
                    margin-bottom: 20px;
                    .linkbtn{
                        display: inline-block;
                        padding:6px 20px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                }
            }
            
            .wap-info-shop{
                background: #f4f7fe;
                padding:0px 10px 0px;
                margin: 0 10px;
                background: red;
                .wap-info-title{
                    width: 100%;
                    
                    padding:10px;
                    border-bottom: 1px solid #dde0ed;
                }
                .wap-info-box{
                    width: 100%;
                    padding:15px 20px 10px;
                    .wap-info-label{
                        
                        padding:0px 0 10px;
                    }
                    .wap-info-list{
                        padding:5px 0;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        .wap-info-name{
                            
                            width:30%;
                            color:#6e6e74;
                        }
                        .wap-info-txt{
                            width:70%;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .text-center{
        .pc-link{
            .pc-linkbtn{display: none;}
        }
    }
    .wrap{
        .pc-shopcar{display:none;}
        .pc-shopintab{display:none;}
        .wap-shopcar{
            background: #fff;
            padding: 0;
            width: 100%;
            .wap-shop-state{
                padding: 10px;
                margin: 0;
                .wap-title-state{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-state-name{
                        
                        .wap-orderstate{
                            display: inline-block;
                            width: 22px;
                            height:22px;
                            background: url(~@/assets/images/icon-wraing.png) no-repeat left top;
                            background-size: 100% 100%;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                    .wap-state-txt{
                        
                        vertical-align: middle;
                        .wap-title{
                            
                        }
                    }
                }
                .wap-pay-state{
                    width: 100%;
                    color: #616166;
                    
                    .wap-txt{
                        display: inline-block;
                        padding:0 0 0 35px;
                        width: 100%;
                        color: #616166;
                        
                    }
                    .wap-label{
                        display: inline-block;
                        width: 28%;
                        padding-left: 30px;
                        color: #616166;
                        
                    }
                    .wap-label_foot{
                        display: inline-block;
                        padding-left: 30px;
                        color: #616166;
                        
                    }
                    .wap-paybtn{
                        display: inline-block;
                        padding:5px 20px;
                        background: #222;
                        color: #fff;
                        cursor: pointer;
                        
                    }
                    .wap-ifos{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 0px;
                        
                    }
                    .wap-ifo{
                        display: inline-block;
                        cursor: pointer;
                        color: #1f73b7;
                        padding:5px 8px;
                        
                    }
                    .wap-leg{
                        display: inline-block;
                        padding:5px 0px;
                        color: #2e2e2e;
                        
                        width: 70%;
                    }
                    
                }
                .wap-marg{
                    margin-top: 10px;
                }
            }
            .wap-shop-wrap{
                padding:10px;
                margin: 0;
                background: #fff;
                .wap-shop-data{
                    width: 100%;
                    padding:0px 0 0px;
                    .wap-shop-item{
                        width: 100%;
                        display: flex;
                        padding:0 0 10px 0;
                        -webkit-box-orient: horizontal;
                        .wap-shop-img{
                            width:30%;
                            padding:0 10px 0 0;
                            img{width:100%}
                        }
                        .wap-shop-txt{
                            width:50%;
                            .wap-title{
                                
                                padding:5px 0;
                            }
                            .wap-txt{
                                
                                padding:0px 0 5px;
                                color: #95959e;
                            }
                            .wap-shopnum{
                                
                                margin-top: 15px;
                            }
                        }
                        .wap-shop-shbtn{
                            width:20%;
                            text-align: right;

                            .wap-del-shopbtn{
                                text-align: right;
                                color: #95959e;
                                padding:10px 0px 0;
                            }
                            .wap-shop-status{
                                
                                color: #1f73b7;
                                padding:10px 0px 0;
                            }   
                        }
                    }
                    
                }
                .wap-calcul{
                    width: 100%;
                    padding:20px 0px;
                    .wap-calcul-group{
                        width: 100%;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        padding:5px 0;
                        .wap-calcul-txt{
                            width: 30%;
                        }
                        .wap-calcul-num{
                            width: 70%;
                            text-align: right;
                            .wap-allnum{
                                color: #9c9da5;
                                margin-left: 4px;
                            }
                        }
                    }
                }
                .wap-calcul-group{
                    width: 100%;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    padding:15px 0px;
                    .wap-calcul-txt{
                        width: 30%;
                    }
                    .wap-calcul-nums{
                        width: 70%;
                        text-align: right;
                        
                    }
                }
                .link{
                    height: 40px;
                    width: 100%;
                    text-align: right;
                    margin-bottom: 20px;
                    .linkbtn{
                        display: inline-block;
                        padding:6px 20px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                }
            }
            
            .wap-info-shop{
                background: #f4f7fe;
                padding:0px 10px 0px;
                margin: 0 10px;
                .wap-info-title{
                    width: 100%;
                    
                    padding:10px;
                    border-bottom: 1px solid #dde0ed;
                }
                .wap-info-box{
                    width: 100%;
                    padding:15px 20px 10px;
                    .wap-info-label{
                        
                        padding:0px 0 10px;
                    }
                    .wap-info-list{
                        padding:5px 0;
                        display: flex;
                        -webkit-box-orient: horizontal;
                        .wap-info-name{
                            
                            width:30%;
                            color:#6e6e74;
                        }
                        .wap-info-txt{
                            width:70%;
                        }
                    }
                }
            }
        }
    }
}
</style>